import { Routes } from '@angular/router';
import { ProductListPage } from './product-list.component';

export const routes: Routes = [
  {
    path: 'products',
    component: ProductListPage,
    title: 'Nuestros productos',
  },
];
