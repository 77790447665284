<main-header></main-header>

<section class="hero hero-overlay py-5" style="background-image: url('./assets/images/hero_home.jpg');">
  <div class="container position-relative h-100">
    <div class="h-100 d-flex flex-column justify-content-center align-items-center pt-5">
      <p class="hero-text mb-0 fs-5 text-light text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
      </p>
      <h1 class="hero-title mb-3 text-light display-6 fw-medium text-center">Expertos en media y baja tensión.</h1>
    </div>
  </div>
</section>

<section class="py-5">
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold text-center">Productos</p>
    <h3 class="fs-1 mb-4 section-title text-center">Baja tensión</h3>

    <div class="row">
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>

<section class="py-5 text-bg-light">
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold text-center">Productos</p>
    <h3 class="fs-1 mb-4 section-title text-center">Media tensión</h3>

    <div class="row">
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>

<section class="py-5">
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold text-center">Productos</p>
    <h3 class="fs-1 mb-4 section-title text-center">Iluminación</h3>

    <div class="row">
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
      <div class="col-lg-4 mb-3">
        <article class="card">
          <img src="https://cdn.shopify.com/s/files/1/0040/8551/4369/files/Aisladores.PNG?v=1606328124"
            class="card-img-top object-fit-cover" alt="" width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
              content.</p>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>

<main-footer></main-footer>