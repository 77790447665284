import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localEsMx from '@angular/common/locales/es-MX';
registerLocaleData(localEsMx, 'es-MX');

import { routes } from './app.routes';
import { routes as home } from './pages/home/home.routes';
import { routes as productList } from './pages/product-list/product-list.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: "es-MX" },
    provideAnimations(),
    provideHttpClient(),

    provideRouter(home, withComponentInputBinding()),
    provideRouter(productList, withComponentInputBinding()),

    provideRouter(routes, withComponentInputBinding()),
  ]
};
