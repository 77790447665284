import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainFooterComponent } from '@components/main-footer/main-footer.component';
import { MainHeaderComponent } from '@components/main-header/main-header.component';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    MainHeaderComponent,
    MainFooterComponent,
    RouterModule
  ],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListPage {

}
